



































































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import { FeedbackRequest, FeedbacksService } from '@/api/braendz';
import { BusyObject } from '@/models/Busy';

@Component({
  components: {
  },
  metaInfo() {
    return {
      title: this.$i18n.t("feedbackPage.title").toString(),
      meta: [
        { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("feedbackPage.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("feedbackPage.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Feedback extends Vue {   
  // Fields
  public step = 1;
  public rating: number | null = null;
  public comment = "";
  public feedbackRequest = new BusyObject<FeedbackRequest>();

  // Getter:
  
  // Watchers & Event Handlers:

  // Component Lifecycle
  
  // Methods
  public submit(): void {
    this.feedbackRequest.create(async () => {
      const request = { 
        context: 'Feedback Page',
        rating: this.rating,
        comment: this.comment
      } as FeedbackRequest;
      
      await FeedbacksService.submitFeedback(request);
      return request;
    });

    this.step = 2;

  }

}
